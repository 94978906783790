@use "sass:map";

$base-colors: (
    primary                     : #51c796,
    secondary                   : #712c50,
    tertiary                    : #4c434e,
    text-dark                   : #393938,
    text-light                  : #ffffff,
    success                     : #31BCA2,
    warning                     : #FFBC25,
    error                       : #f04141,
);

@function hexToRGB($hex) {
    @return "#{red($hex)}, #{green($hex)}, #{blue($hex)}";
}

@mixin themeVariables($name, $color, $contrast) {
    --color-#{$name}: #{$color};
    --color-#{$name}-rgb: #{hexToRGB($color)};
    --color-#{$name}-contrast: #{$contrast};
    --color-#{$name}-contrast-rgb: #{hexToRGB($contrast)};
}

:root {
    @include themeVariables(primary, map.get($base-colors, primary), map.get($base-colors, text-light));
    @include themeVariables(secondary, map.get($base-colors, secondary), map.get($base-colors, text-light));
    @include themeVariables(tertiary, map.get($base-colors, tertiary), map.get($base-colors, text-light));

    @include themeVariables(success, map.get($base-colors, success), map.get($base-colors, text-light));
    @include themeVariables(warning, map.get($base-colors, warning), map.get($base-colors, text-dark));
    @include themeVariables(error, map.get($base-colors, error), map.get($base-colors, text-light));

    @include themeVariables(text-dark, map.get($base-colors, text-dark), map.get($base-colors, text-light));
    @include themeVariables(text-light, map.get($base-colors, text-light), map.get($base-colors, text-dark));
}
