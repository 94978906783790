@font-face {
    font-family: "icons";
    src: url("/assets/fonts/icomoon/icomoon.eot?s3bv5y");
    src: url("/assets/fonts/icomoon/icomoon.eot?s3bv5y#iefix") format("embedded-opentype"),
        url("/assets/fonts/icomoon/icomoon.ttf?s3bv5y") format("truetype"),
        url("/assets/fonts/icomoon/icomoon.woff?s3bv5y") format("woff"),
        url("/assets/fonts/icomoon/icomoon.svg?s3bv5y#icomoon") format("svg");

    font-weight: normal;
    font-style: normal;
    font-display: block;
}

.icon {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: "icons" !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-arrow-left:before {
    content: "\e900";
}

.icon-arrow-right:before {
    content: "\e901";
}

.icon-person:before {
    content: "\e902";
}

.icon-arrow-down:before {
    content: "\e903";
}

.icon-cross:before {
    content: "\ea0f";
}
