@use "breakpoints";
@use "helpers/mixins";

html, body {
    text-rendering: geometricPrecision;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    font-weight: 500;
    font-size: 14px;
    line-height: 1.5;

    @include breakpoints.media-breakpoint-up(laptop) {
        font-size: 16px;
    }
}

h1 {
    @include mixins.heading-1;
}

h2 {
    @include mixins.heading-2;
}

h3 {
    @include mixins.heading-3;
}

h4 {
    @include mixins.heading-4;
}

h5 {
    @include mixins.heading-5;
}

.lead-body {
    @include mixins.lead-body;
}

.label-medium {
    @include mixins.label-semibold;
}

.button-text {
    @include mixins.button-text;
}
