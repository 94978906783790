.fill-height {
    height: 100%;
}

.fill-width {
    width: 100%;
}

.fill-wh {
    @extend .fill-height;
    @extend .fill-width;
}

.fill-v-height {
    height: 100vh;
}

.fill-v-width {
    width: 100vw;
}

.fill-v-wh {
    @extend .fill-v-height;
    @extend .fill-v-width;
}

.stick-bottom {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
}

.center-item {
    display: flex;
    align-items: center;
    justify-content: center;
}

.app-margin {
    margin-left: 20px;
    margin-right: 20px;
}

.line {
    --line-margin-top: 25px;
    --line-margin-bottom: 25px;
    --line-opacity: 1;
    --line-width: 100px;
    --line-height: 1px;
    width: var(--line-width);
    height: var(--line-height);
    margin: var(--line-margin-top) 0px var(--line-margin-bottom) 0px;
    opacity: var(--line-opacity);

    background-color: var(--color-background-overlay);
}

@keyframes shine {
    to {
        background-position-x: -200%;
    }
}

.skeleton-loading {
    background: #eee;
    background: linear-gradient(110deg, #ececec 8%, rgb(210, 210, 210) 18%, #ececec 33%);
    border-radius: 5px;
    background-size: 200% 100%;
    animation: 1.5s shine linear infinite;
    color: transparent !important;
}
