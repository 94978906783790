@use 'src/theme/breakpoints';
@use 'sass:list';

:root {
    --app-default-space: 20px;
    --app-x-space: 20px;

    --app-max-width: 1320px;

    @include breakpoints.media-breakpoint-up(laptop) {
        --app-x-space: 150px;
    }
}

// ------------------------------------------------------------------------------
//      Typography mixins
// ------------------------------------------------------------------------------

@mixin app-regular-font() {
    font-weight: 400;
    @include reset-margins;
}

@mixin app-bold-font() {
    font-weight: bold;
    @include reset-margins;
}

@mixin reset-margins {
    margin: 0;
}

@mixin heading-1 {
    @include app-bold-font;
    --font-size: 44px;

    @include breakpoints.media-breakpoint-up(tablet) {
        --font-size: 52px;
    }
    @include breakpoints.media-breakpoint-up(laptop) {
        --font-size: 64px;
    }

    font-size: var(--font-size);
}

@mixin heading-2 {
    @include app-bold-font;

    --font-size: 32px;
    --line-height: 36px;

    @include breakpoints.media-breakpoint-up(tablet) {
        --font-size: 40px;
        --line-height: 52px;
    }
    @include breakpoints.media-breakpoint-up(laptop) {
        --font-size: 48px;
        --line-height: 52px;
    }

    font-size: var(--font-size);
    line-height: var(--line-height);
}

@mixin heading-3 {
    @include app-bold-font;

    --font-size: 24px;
    --line-height: 28px;

    @include breakpoints.media-breakpoint-up(tablet) {
        --font-size: 32px;
        --line-height: 40px;
    }
    @include breakpoints.media-breakpoint-up(laptop) {
        --font-size: 36px;
        --line-height: 40px;
    }

    font-size: var(--font-size);
    line-height: var(--line-height);
}

@mixin heading-4 {
    @include app-bold-font;

    --font-size: 20px;
    --line-height: 24px;

    @include breakpoints.media-breakpoint-up(tablet) {
        --font-size: 28px;
        --line-height: 32px;
    }

    font-size: var(--font-size);
    line-height: var(--line-height);
}

@mixin heading-5 {
    @include app-bold-font;
    font-size: 14px;
    line-height: 14.4px;
    letter-spacing: 2.8px;
}

@mixin lead-body {
    --font-weight: 500;
    --font-size: 18px;
    --line-height: 28px;

    @include breakpoints.media-breakpoint-up(tablet) {
        --font-size: 20px;
    }

    font-size: var(--font-size);
    line-height: var(--line-height);
    font-weight: var(--font-weight);
}

@mixin body {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
}

@mixin label-semibold {
    font-weight: 600;
    font-size: 16px;
    line-height: 28px;
}

@mixin label-small {
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
}

@mixin button-text {
    --font-weight: 700;
    --font-size: 16px;

    @include breakpoints.media-breakpoint-up(tablet) {
        --font-size: 18px;
    }

    font-weight: var(--font-weight);
    font-size: var(--font-size);
}

@mixin quote {
    font-weight: 400;
    --font-size: 26px;
    --line-height: 32px;

    @include breakpoints.media-breakpoint-up(tablet) {
        --font-size: 32px;
        --line-height: 40px;
    }
    @include breakpoints.media-breakpoint-up(laptop) {
        --font-size: 48px;
        --line-height: 60px;
    }

    font-size: var(--font-size);
    line-height: var(--line-height);
}

// ------------------------------------------------------------------------------
//      Space mixins
// ------------------------------------------------------------------------------

@mixin app-x-padding() {
    padding-left: var(--app-x-space);
    padding-right: var(--app-x-space);

    @include breakpoints.media-breakpoint-up(laptop) {
        padding-left: var(--app-x-space);
        padding-right: var(--app-x-space);
    }
}

@mixin app-x-margin {
    margin-left: var(--app-x-space);
    margin-right: var(--app-x-space);

    @include breakpoints.media-breakpoint-up(laptop) {
        margin-left: var(--app-x-space);
        margin-right: var(--app-x-space);
    }
}

@mixin app-max-width {
    max-width: var(--app-max-width);
    margin: 0 auto;
}

@mixin full-width-less-x-space() {
    width: calc(100% - var(--app-x-space) * 2);
}

// ------------------------------------------------------------------------------
//      Space mixins
// ------------------------------------------------------------------------------

@mixin transition($transition) {
    -webkit-transition: $transition;
    -moz-transition: $transition;
    -ms-transition: $transition;
    -o-transition: $transition;
    transition: $transition;
}

@mixin color-transition($duration: 0.2s, $easing: ease-out, $additionalProps: ()) {
    $props: list.join((background-color, color, fill), $additionalProps);
    $value: "";

    @each $prop in $props {
        $value: #{$value}, $prop $duration $easing
    }

    @include transition($value);
}

// ------------------------------------------------------------------------------
//      Shadows
// ------------------------------------------------------------------------------

@mixin shadow-large {
    --box-shadow: 0 15px 30px rgba(0, 0, 0, 0.2);
    box-shadow: var(--box-shadow);
}

@mixin shadow-medium {
    --box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
    box-shadow: var(--box-shadow);
}

@mixin shadow-small {
    --box-shadow: 0 4px 3px 0 rgb(0, 0, 0, 0.16);
    box-shadow: var(--box-shadow);
}

@mixin drop-small-shadow {
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}

// ------------------------------------------------------------------------------
//      Images
// ------------------------------------------------------------------------------

@mixin background-image() {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

// ------------------------------------------------------------------------------
//      Containers
// ------------------------------------------------------------------------------

@mixin full-size-container {
    width: 100%;
    height: 100%;
}
