// Custom scss
@import "theme/variables";
@import "theme/colors";
@import "theme/typography";
@import "theme/breakpoints";
@import "theme/helpers/mixins";
@import "theme/general";

// Custom Components
@import "theme/ui/ui";
@import "theme/components/components";

html,
body {
    height: 100vh;
    margin: 0;
    font-family: "Gilroy", Arial, Helvetica, sans-serif;
}

* {
    box-sizing: border-box;
}
